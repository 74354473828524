import { route } from '@md/routing';
import { unidentifiedSearchApi } from '@md/delivery/api';
import { accessPermission } from '@md/users/permissions';
import {
  DELIVERY_UNIDENTIFIED_SEARCH_VIEW_LIST,
  DELIVERY_UNIDENTIFIED_SEARCH_VIEW,
  DELIVERY_UNIDENTIFIED_SEARCH_CREATE,
} from '@md/delivery/accessTokens';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');
const Create = () => import('./views/Create');

const UNIDENTIFIED_PREFIX = 'cabinet:delivery:unidentified-search:';
const p = name => UNIDENTIFIED_PREFIX + name;
export const LIST = p('list');
export const EDIT = p('edit');
export const CREATE = p('create');

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(DELIVERY_UNIDENTIFIED_SEARCH_VIEW_LIST, options),
    }),
    receiveRoute(unidentifiedSearchApi.retrieve, [
      route('edit/', Edit, EDIT, {
        meta: accessPermission(DELIVERY_UNIDENTIFIED_SEARCH_VIEW, options),
      }),
      { path: '', redirect: { name: EDIT } },
    ]),
    route('create/', Create, CREATE, {
      meta: accessPermission(DELIVERY_UNIDENTIFIED_SEARCH_CREATE, options),
    }),

    { path: '', redirect: { name: LIST } },
  ];
}
